import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../logo.svg'
import './Navbar.css';  // You can style the header in the existing CSS file

const Header: React.FC = () => {

  return (

    <nav className="nav-header">
      <div >
        <img src={Logo} alt="Logo" className="logo"/>
      </div>
      <div className='nav-links'>
        <div>
          <Link to="/home">Home</Link>
        </div>
        <div>
          <Link to="/products">Products</Link>
        </div>
        <div>
          <Link to="/contact">Contact Us</Link>
        </div>
      </div>
    </nav>
  );
};

export default Header;