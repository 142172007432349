import React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import './ContactPage.css'; // Import the CSS file for styling

// Define the interface for form data
interface IFormInput {
  name: string;
  email: string;
  message: string;
}

const ContactPage: React.FC = () => {
  const { register, handleSubmit, reset } = useForm<IFormInput>();

  // Utility function to detect if the user is on a mobile device
  const isMobileDevice = () => {
    return /Mobi|Android/i.test(navigator.userAgent);
  };

  // Typing the 'data' parameter with IFormInput explicitly
  const onSubmit: SubmitHandler<IFormInput> = (data: IFormInput) => {
    const subject = `Message from ${data.name}`;
    const body = `Name: ${data.name}\nEmail: ${data.email}\nMessage: ${data.message}`;

    // Create a mailto link
    const mailtoLink = `mailto:${data.email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

    // Detect whether the user is on mobile or desktop and open the email client
    if (isMobileDevice()) {
      // Open mailto link in mobile Gmail app
      window.location.href = mailtoLink;
    } else {
      // Open mailto link in the default desktop email client
      window.open(mailtoLink, '_blank');
    }

    reset(); // Reset the form after submission
  };

  return (
    <div className="contact-page">
      <h1>Contact Us</h1>
      <form className="contact-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group">
          <label htmlFor="name">Name</label>
          <input
            id="name"
            {...register('name', { required: true })}
            type="text"
            placeholder="Your Name"
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            id="email"
            {...register('email', { required: true })}
            type="email"
            placeholder="Your Email"
          />
        </div>
        <div className="form-group">
          <label htmlFor="message">Message</label>
          <textarea
            id="message"
            {...register('message', { required: true })}
            placeholder="Your Message"
          />
        </div>
        <button type="submit" className="submit-btn">Send Message</button>
      </form>
    </div>
  );
};

export default ContactPage;
