import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import HomePage from './home/HomePage';
import ProductsPage from './products/ProductsPage';
import ContactPage from './contacts/ContactPage';
import Navbar from './components/Navbar';

function App() {

  return (
    <div className="App">
      <Router>
      <Navbar />

      <Routes>
        {/* Redirect from "/" to "/home" */}
        <Route path="/" element={<Navigate to="/home" />} />

        {/* Home Page */}
        <Route path="/home" element={<HomePage />} />

        {/* Products Page */}
        <Route path="/products" element={<ProductsPage />} />

        {/* Contact Us Page */}
        <Route path="/contact" element={<ContactPage />} />
      </Routes>   
      </Router>
    </div>
  );

//   return (
// <Router>
//       {/* Add the Header component */}
//       <Header />
//       <Routes>
//         {/* Redirect from "/" to "/home" */}
//         <Route path="/" element={<Navigate to="/home" />} />

//         {/* Home Page */}
//         <Route path="/home" element={<HomePage />} />

//         {/* Products Page */}
//         <Route path="/products" element={<ProductsPage />} />

//         {/* Contact Us Page */}
//         <Route path="/contact" element={<ContactPage />} />
//       </Routes>
//     </Router>
//   );

  // return (
  //   <div className="App">

  //     <img src={logo} className="App-logo" alt="logo" />
  //     <div className='Home-top-container'>
  //       <p className='Home-title-text'>
  //       Enhance your steeting using exclusive Paddle Shifters
  //       </p>
  //       <div>
  //     <img src={PaddleShifterImageRed} className="Home-image"/>

  //       </div>

  //     </div>

  //   </div>
  // );
}

export default App;
