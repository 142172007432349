import React, { useState } from 'react';
import './HomePage.css'
import ProductCard from '../ProductCard';
import PaddleShifterImageRed from '../paddle-shifters-red.png'
import HeroSection from './HeroSection';
import { Product } from '../types/Product.type';



const HomePage: React.FC = () => {
  const primaryProduct: Product = {
    id: 1,
    name: 'Volkswagen Paddle Shifters Extenders',
    price: 50,
    rating: 4,
    relevance: 2,
    imageUrl: PaddleShifterImageRed, // Replace with your image URL
  };

  const otherProducts: Product[] = [
    { id: 2, name: 'Metal Alloy key cover for Skoda and Volkswagen', price: 1299, rating:4.2, relevance:1, imageUrl: 'https://m.media-amazon.com/images/I/51yrUZe9bnL._SX679_.jpg' },
    { id: 2, name: 'Metal Alloy key cover for Skoda and Volkswagen', price: 1299, rating:4.2, relevance:1, imageUrl: 'https://m.media-amazon.com/images/I/51yrUZe9bnL._SX679_.jpg' },
    { id: 2, name: 'Metal Alloy key cover for Skoda and Volkswagen', price: 1299, rating:4.2, relevance:1, imageUrl: 'https://m.media-amazon.com/images/I/51yrUZe9bnL._SX679_.jpg' },
 
    // Add more products here
  ];

  const [cart, setCart] = useState<Product[]>([]); // Cart state

  const handleAddToCart = (product: Product) => {
    setCart((prevCart) => [...prevCart, product]);
  };


  return (
    <div className="home-page">
      {/* Hero Section */}
      <HeroSection />

      {/* Product Grid Section */}
      <div className="product-grid-home">
        <h2>Other Products</h2>
        <div className="grid-home">
          {otherProducts.map((product) => (
            <ProductCard key={product.id} product={product} onAddToCart={handleAddToCart}/>
          ))}
        </div>
      </div>

      {/* Footer */}
      <footer className="footer">
        <p>© 2024 Turbo Mods. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default HomePage;