import React from 'react';
import './ProductCard.css';
import { Product } from './types/Product.type';

interface ProductCardProps {
  product: Product;
  onAddToCart: (product: Product) => void;
}

const ProductCard: React.FC<ProductCardProps> = ({ product, onAddToCart }) => {
  return (
    <div className="product-card-home">
      <img src={product.imageUrl} alt={product.name} className="product-image-home" />
      <h3>{product.name}</h3>
      <p>${product.price.toFixed(2)}</p>
      <button className="add-to-cart-btn" onClick={() => onAddToCart(product)}>
        Add to Cart
      </button>
    </div>
  );
};

export default ProductCard;
