import React from 'react';
import './HeroSection.css';
import PaddleShiftersImage from '../paddle-shifters-red.png'

const HeroSection: React.FC = () => {
  return (
    <section className="hero-section">
      <div className="hero-content">
        <h1>The perfect paddle shifters <br/> for your car</h1>
        <p>Jesteśmy <span className="highlight">TIGERS</span>. Kreujemy wizerunek marki i skalujemy zyski klientów.</p>
        <button className="hero-button">NAPISZ DO NAS</button>
      </div>
      <div className="hero-image">
        <img src={PaddleShiftersImage} alt="Digital Jungle" />
      </div>
    </section>
  );
}

export default HeroSection;