import React, { useState } from 'react';
import { Product } from '../types/Product.type';
import { products } from '../data/Products';
import './ProductsPage.css'; // Add your CSS styles here

const sortOptions = {
  price: 'Price',
  rating: 'Rating',
  name: 'Name',
  relevance: 'Relevance',
};

const ProductPage: React.FC = () => {
  const [sortedProducts, setSortedProducts] = useState<Product[]>(products);
  const [sortCriterion, setSortCriterion] = useState<keyof typeof sortOptions>('relevance');

  const handleSortChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const criterion = event.target.value as keyof typeof sortOptions;
    setSortCriterion(criterion);

    const sorted = [...products].sort((a, b) => {
      if (criterion === 'name') {
        return a.name.localeCompare(b.name);
      }
      return (a[criterion] - b[criterion]);
    });

    setSortedProducts(sorted);
  };

  return (
    <div className="product-page">
      <div className="sort-section">
        <label htmlFor="sort">Sort by:</label>
        <select id="sort" value={sortCriterion} onChange={handleSortChange}>
          {Object.entries(sortOptions).map(([key, value]) => (
            <option key={key} value={key}>{value}</option>
          ))}
        </select>
      </div>
      <div className="product-grid">
        {sortedProducts.map(product => (
          <div key={product.id} className="product-card">
            <img src={product.imageUrl} alt={product.name} className="product-image" />
            <h3 className="product-name">{product.name}</h3>
            <p className="product-price">${product.price.toFixed(2)}</p>
            <p className="product-rating">Rating: {product.rating}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductPage;